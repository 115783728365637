import React from "react";
import { styled } from "goober";

const GRID_BASE_SIZE = 63;

interface ItemGridProps {
  width: number;
  height: number;
  img?: string;
  alt?: string;
  className?: string;
}

const Grid = styled("div")`
  --grid-bg-color: var(--shade9-50);
  --grid-line-color: var(--shade6);

  position: relative;
  /* Doing some black magic with repeating-linear-gradient to make the internal lines */
  background-image: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent calc(var(--grid-size, 63px) - 1px),
      var(--grid-line-color) var(--grid-size, 63px),
      var(--grid-line-color) calc(var(--grid-size, 63px) + 1px)
    ),
    repeating-linear-gradient(
      to right,
      var(--grid-bg-color),
      var(--grid-bg-color) calc(var(--grid-size, 63px) - 1px),
      var(--grid-line-color) var(--grid-size, 63px),
      var(--grid-line-color) calc(var(--grid-size, 63px) + 1px)
    );
  border: 2px solid var(--grid-line-color);
  border-radius: var(--br-lg);
  width: calc(var(--grid-x, 1) * var(--grid-size, 63px));
  height: calc(var(--grid-y, 1) * var(--grid-size, 63px));

  img {
    margin: 0 auto;
    max-height: 98%;
    max-width: 98%;
  }
`;

function ItemGrid({ width, height, img, alt, className }: ItemGridProps) {
  return (
    <Grid
      style={{
        "--grid-x": width,
        "--grid-y": height,
        "--grid-size": `${GRID_BASE_SIZE}px`,
      }}
      className={className}
    >
      {img && <img src={img} alt={alt} />}
    </Grid>
  );
}

export default React.memo(ItemGrid);
